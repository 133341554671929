export default {
  install (Vue) {
    Vue.prototype.$cookieNotification = new Vue({
      data () {
        return {
          applicationName: 'Temper'
        }
      },
      created () {
        let cookie = this.$cookies.read(this.applicationName)
        if (!cookie) {
          this.$buefy.snackbar.open({
            indefinite: true,
            message: 'We use cookies to provide better customer experiences. Continue browsing if you\'re happy with this, or <a href="/privacy-policy" target="_blank" title="Privacy Policy">click here</a> for more information.',
            type: 'is-danger',
            actionText: 'OK',
            queue: false,
            onAction: () => {
              this.$cookies.create(this.applicationName, true, 7)
            }
          })
        }
      }
    })
  }
}
