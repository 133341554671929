import Vue from 'vue'
import VueHelpers from '@wyxos/vue-helpers'
import { ConfigProgrammatic, Snackbar, Loading, Carousel, Button } from 'buefy'
import Navigation from './plugins/navigation'
import CookieNotification from './plugins/cookie-notification'
import Header from './plugins/header'

window.$ = window.jQuery = require('jquery')

/** Buefy Configurations and components */
ConfigProgrammatic.setOptions({
  defaultIconPack: 'fas'
})
Vue.use(Snackbar)
Vue.use(Loading)
Vue.use(Button)
Vue.use(Carousel)

Vue.use(VueHelpers)
Vue.use(Navigation)
Vue.use(Header)
Vue.use(CookieNotification)

/* const files = require.context('./components', true, /\.vue$/i)
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default)) */

/* eslint-disable no-unused-vars */
const app = new Vue({
  el: '#app',
  data: {
    isFullPage: true,
    isReady: false
  },
  mounted () {
  }
})
